import React, { useEffect, useState, useContext } from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import { Context } from 'context/AuthContext';
import { GET_LIST_AVISO } from 'services/Aviso';
import { COUNT_PENDENCIA_USUARIO_ATUAL } from 'services/Pendencia';
import { COUNT_RASCUNHO_USUARIO_ATUAL } from 'services/Rascunho';

import rascunho from 'assets/imgs/icons/rascunho.png';
import pendencias from 'assets/imgs/icons/pendencias.png';
// import onoff from 'assets/imgs/icons/plano_midias_on_off.png';
// import atendimento from 'assets/imgs/icons/atendimento_ao_usuario.png';
import consultar from 'assets/imgs/icons/consultar.png';
import materiais from 'assets/imgs/icons/icon_materiais.svg';
import histpedidos from 'assets/imgs/icons/icon_histpedidos.svg';
import bancoartes from 'assets/imgs/icons/plano_midias_on_off.png';
import relatorios from 'assets/imgs/icons/relatorios.png';
import artefacil from 'assets/imgs/icons/artes_facil.png';

import { GET_SITE_CONFIG } from 'services/SysConfig';
import './styles.scss';

const ContentHome = () => {
  const [warnings, setWarnings] = useState([]);
  const { showLoader } = useContext(Context);
  const [corPendencia, setCorPendencia] = useState([]);
  const [corRascunho, setCorRascunho] = useState([]);
  const [mensagem, setMensagem] = useState('');

  async function getHomeMessage() {
    const data = await GET_SITE_CONFIG({ keyFilter: 'MENSAGEM_HOME' });
    if (data.value != null && data.value.length > 0) {
      return data.value[0].value;
    }

    return '';
  }

  useEffect(() => {
    async function fetchData() {
      showLoader(true);

      const msg = await getHomeMessage();
      setMensagem(msg);

      const corPendenciaResult = await COUNT_PENDENCIA_USUARIO_ATUAL();
      setCorPendencia(corPendenciaResult.value);

      const countUsuarioAtual = await COUNT_RASCUNHO_USUARIO_ATUAL();
      setCorRascunho(countUsuarioAtual.value);

      const warningsResult = await GET_LIST_AVISO();
      setWarnings(warningsResult.value);

      showLoader(false);
    }
    fetchData();
  }, []);

  return (
    <>
      <div className="wrapper-choose-the-costumer">
        <div className="warnings">
          <Carousel>
            {warnings?.map((item) => {
              return (
                <Carousel.Item key={item.avisoId}>
                  {item.urlRedirecionamento ? (
                    <a
                      href={item.urlRedirecionamento}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="d-block w-100"
                        src={item.urlImagem}
                        alt={item.texto}
                      />
                    </a>
                  ) : (
                    <img
                      className="d-block w-100"
                      src={item.urlImagem}
                      alt={item.texto}
                    />
                  )}
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>

        {/* <div className="actions"> */}
        <div className="actions">
          <div>
            <Link to="/pedido-materiais?materialComunicacaoTipo=MG">
              <div>
                <div className="button-content">
                  <div>
                    <img src={materiais} alt="Materiais Gráficos" />
                  </div>
                  <div className="btn-divider" />
                  <div>Solicitar Materiais Gráficos</div>
                </div>
                <div className="button-content-hover">
                  <p>
                    Solicite materiais personalizados com qualquer layout,
                    produto ou dimensões necessárias.
                  </p>
                </div>
              </div>
            </Link>
            <Link to="/pedido-materiais?materialComunicacaoTipo=MD">
              <div>
                <div className="button-content">
                  <div>
                    <img src={artefacil} alt="Mídias Digitais" />
                  </div>
                  <div className="btn-divider" />
                  <div>Solicitar Mídias Digitais</div>
                </div>
                <div className="button-content-hover">
                  <p>
                    Solicite artes digitais personalizadas com qualquer layout,
                    produto ou dimensões necessárias.
                  </p>
                </div>
              </div>
            </Link>
          </div>

          <div>
            <Link to="/banco-de-artes">
              <div>
                <div className="button-content">
                  <div>
                    <img src={bancoartes} alt="Consultar" />
                  </div>
                  <div className="btn-divider" />
                  <div>Banco de artes</div>
                </div>
                <div className="button-content-hover">
                  <p>Banco de Artes já feitas na plataforma.</p>
                </div>
              </div>
            </Link>
            <Link to="/historico-pedidos">
              <div>
                <div className="button-content">
                  <div>
                    <img src={histpedidos} alt="Historico pedido" />
                  </div>
                  <div className="btn-divider" />
                  <div>Histórico de pedidos</div>
                </div>
                <div className="button-content-hover">
                  <p>
                    Histórico de seus pedidos feitos caso queira reaproveitá-los
                    para solicitar novamente
                  </p>
                </div>
              </div>
            </Link>
          </div>

          <div>
            <Link to="/rascunho">
              <div style={{ backgroundColor: corRascunho > 0 ? 'red' : '' }}>
                <div className="button-content">
                  <div>
                    <img src={rascunho} alt="Rascunhos" />
                  </div>
                  <div className="btn-divider" style={{ marginLeft: '10px' }} />
                  <div>Rascunhos</div>
                </div>
                <div className="button-content-hover">
                  <p>Rascunhos de solicitações não enviadas.</p>
                </div>
              </div>
            </Link>
            <Link to="/pendencias">
              <div style={{ backgroundColor: corPendencia > 0 ? 'red' : '' }}>
                <div className="button-content">
                  <div>
                    <img src={pendencias} alt="pendencias" />
                  </div>
                  <div className="btn-divider" />
                  <div>Pendências</div>
                </div>
                <div className="button-content-hover">
                  <p>
                    Solicitações pendentes de alguma ação (aprovação /
                    reprovação)
                  </p>
                </div>
              </div>
            </Link>
          </div>

          <div>
            <Link to="/consulta">
              <div>
                <div className="button-content">
                  <div>
                    <img src={consultar} alt="Consultar" />
                  </div>
                  <div className="btn-divider" />
                  <div>Consultar</div>
                </div>
                <div className="button-content-hover">
                  <p>Consulta de solicitações realizadas no sistema.</p>
                </div>
              </div>
            </Link>

            <Link to="/relatorios">
              <div>
                <div className="button-content">
                  <div>
                    <img src={relatorios} alt="Relatórios" />
                  </div>
                  <div className="btn-divider" />
                  <div>Relatórios</div>
                </div>
                <div className="button-content-hover">
                  <p>Relatórios.</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center text-center mb-3">
        <h5 className="text-primary font-weight-bold home-horario-atendimento">
          {mensagem}
        </h5>
      </div>
    </>
  );
};

const App = () => {
  return (
    <>
      <HeaderApp />
      <ContentHome />
      <Bottom />
    </>
  );
};

export default App;
