/* eslint-disable */
import React from 'react';

import './styles.scss';
import BrandColor from 'assets/imgs/logo_cliente.png';
import BrandWhite from 'assets/imgs/logo_branco.png';

const Brand = (props) => {
  const { white } = props;
  return <img className="brand" src={white ? BrandWhite : BrandColor} alt="Drylock" />;
};

export default Brand;
