/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FormGroup, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPaperPlane,
  faAngleLeft,
} from '@fortawesome/free-solid-svg-icons';

import TitlePages from 'components/_base/TitlePages';
import Question from 'components/Question';
import Response from 'components/Response';
import actionLoader from 'store/modules/loader/actions';
import history from 'routes/history';
import InputFileCustom from 'components/_base/InputFile';
import { Context } from 'context/AuthContext';
import api from 'api';
import {
  GET_CHAMADO,
  ADD_MENSAGEM,
  UPDATE_FECHAR_CHAMADO,
} from 'services/Chamado';

import ModalDiscardChamado from './ModalDiscardChamado';
import './styles.scss';

const ContentChatChamado = () => {
  const userAuth = JSON.parse(localStorage.getItem('encartes_drylock_site'));
  const paramsLocation = new URLSearchParams(useLocation().search);
  const [chamados, setChamados] = useState({});
  const [chamadoId, setChamadoId] = useState(0);
  const { showLoader } = useContext(Context);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  const handleDiscard = async () => {
    dispatch(actionLoader.showLoader(true));

    const data = await UPDATE_FECHAR_CHAMADO(chamados.chamadoId);

    dispatch(actionLoader.showLoader(false));

    handleClose();

    if (data.value === true) {
      history.push('/chamado-finalizado/');
    }
  };

  const handleClose = async () => {
    setChamadoId(0);
  };

  const getChamados = async () => {
    showLoader(true);
    const data = await GET_CHAMADO(paramsLocation.get('id'));

    data.value.mensagem = '';
    data.value.arquivo = null;
    setChamados(data.value);
    showLoader(false);
  };

  useEffect(() => {
    (async () => {
      await getChamados();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let element = document.getElementById('conteudo_conversa');
      element.scrollTop = element.scrollHeight;
    })();
  }, [chamados]);

  const onSubmit = async () => {
    const formData = new FormData();

    formData.append('chamadoId', chamados.chamadoId);
    formData.append('mensagem', chamados.mensagem);
    formData.append('usuarioId', userAuth.userId);
    formData.append('arquivo', chamados.arquivo);

    if (
      chamados.mensagem === '' ||
      chamados.mensagem === chamados.chamadoRespostas[0].resposta
    ) {
      setErrorMessage('Escreva sua mensagem  no campo abaixo!');
    } else {
      showLoader(true);
      const data = await ADD_MENSAGEM(formData);

      if (data.value === false && data.errors && data.errors.length > 0) {
        setErrorMessage(data.errors[0]);
      } else {
        await getChamados();
        //history.push(`/acompanhar-chamado/${chamados.chamadoId}`);
      }
    }
    showLoader(false);
  };

  function onChangeInput(event) {
    const { value, name } = event.target;

    setChamados({
      ...chamados,
      [name]: value,
    });
  }

  function onChangeFile(event, name) {
    setChamados({
      ...chamados,
      [name]: event.target.files[0],
    });
  }

  return (
    <div className="wrapper-chat-chamado">
      <TitlePages
        className="page-title"
        title={`CHAMADO ${chamados?.chamadoId} - ${chamados?.chamadoTipo?.nome}`}
      />
      <div className="contents">
        <div id="conteudo_conversa" className="conversa col-lg-8 col-md-8  ">
          <div className="conteudo">
            {chamados &&
              chamados.chamadoRespostas &&
              chamados.chamadoRespostas.map((resposta) => {
                if (resposta.usuarioId != userAuth.userId) {
                  return <Response resposta={resposta} />;
                } else {
                  return <Question resposta={resposta} />;
                }
              })}
          </div>
        </div>
        {errorMessage && (
          <Alert
            variant="danger"
            onClose={() => setErrorMessage('')}
            dismissible
          >
            {errorMessage}
          </Alert>
        )}
        {!chamados.dataFinalizacao && (
          <FormGroup className=" mensagem col-lg-8 col-md-8">
            <textarea
              type="input"
              name="mensagem"
              onChange={onChangeInput}
              className="col-lg-12"
              placeholder="Digite sua mensagem"
              value={chamados.mensagem}
            ></textarea>
          </FormGroup>
        )}

        <div
          style={{ display: chamados.dataFinalizacao !== null ? 'none' : '' }}
          className="responder col-lg-8 col-md-8 "
        >
          <FormGroup className="arquivo ">
            <InputFileCustom
              name="arquivo"
              onChange={onChangeFile}
              file={chamados.arquivo}
            ></InputFileCustom>
          </FormGroup>
          <FormGroup className=" btnResponder ">
            <Button className="input botao" onClick={onSubmit}>
            <FontAwesomeIcon className="icon" icon={faPaperPlane} />
              RESPONDER
            </Button>
          </FormGroup>
        </div>

        <div className="voltar col-lg-8 col-md-8 ">
          <FormGroup className="">
            <Button className=" btnVoltar" href="/acompanhar-chamado">
              <FontAwesomeIcon className="icon" icon={faAngleLeft} />
              VOLTAR
            </Button>
          </FormGroup>
          <FormGroup
            style={{ display: chamados.dataFinalizacao !== null ? 'none' : '' }}
            className=""
          >
            <Button onClick={() => setChamadoId(chamados.chamadoId)}>
              FINALIZAR
            </Button>
          </FormGroup>
        </div>
      </div>
      <ModalDiscardChamado
        chamados={chamadoId}
        onClose={handleClose}
        onDiscard={handleDiscard}
      ></ModalDiscardChamado>
    </div>
  );
};

export default ContentChatChamado;
