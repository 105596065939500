/* eslint-disable */
import React, { useState, useContext } from 'react';
import { Form, Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

import { Context } from 'context/AuthContext';
import './styles.scss';

const FormLogin = () => {
  const params = new URLSearchParams(useLocation().search);

  const initialState = {
    username: '',
    password: '',
    redirect: params.get('urlReturn') ? decodeURIComponent(params.get('urlReturn')) : '/',
  };

  const [values, setValues] = useState(initialState);
  const [erroLogin, setErroLogin] = useState(false);
  const { handleLogin } = useContext(Context);

  function onChange(event) {
    const { value, name } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  }

  async function onSubmit(event) {
    event.preventDefault();
    const authenticated = await handleLogin(values);
    setErroLogin(!authenticated);
  }

  return (
    <div style={{ marginTop: '13px' }}>
      <label htmlFor="check">
        <FontAwesomeIcon icon={faBars} size="2x" />
      </label>

      <input type="checkbox" id="check" />

      <label htmlFor="check">
        <div className="turn-off-the-light" />
      </label>
      <Form onSubmit={(e) => onSubmit(e)}>
        <div className="form-login">
          <div>
            <label htmlFor="check">
              <FontAwesomeIcon icon={faTimes} size="1x" />
            </label>
          </div>
          <div>
            <Form.Group controlId="formLogin">
              <Form.Control
                type="email"
                placeholder="Login"
                name="username"
                onChange={onChange}
              />
              {erroLogin && (
                <Badge pill variant="danger pl-2 pr-2 mt-1 mb-1">
                  <p className="mb-0 pb-0">
                    Nome de usuário ou senha incorreta.
                  </p>
                </Badge>
              )}
            </Form.Group>
          </div>
          <div>
            <Form.Group controlId="formPassword">
              <Form.Control
                type="password"
                placeholder="Senha"
                name="password"
                onChange={onChange}
              />
              <a href="/recuperar-senha">
                <b>Esqueceu sua senha?</b>
              </a>
            </Form.Group>
          </div>

          <div>
            <button
              onClick={onSubmit}
              type="submit"
              className="btn btn-primary text-white"
            >
              Entrar
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default FormLogin;
