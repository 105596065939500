/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import { GET_SITE_CONFIG } from 'services/SysConfig';
import './styles.scss';

const Contact = () => {
  const [download, setDownload] = useState([]);
  const [horario, setHorario] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    (async () => {
      const manual_link = await getManualLink();
      setDownload(manual_link || '');

      const horario = await getAtendimentoHorario();
      setHorario(horario || '');

      const telefone = await getAtendimentoTelefone();
      setTelefone(telefone || '');

      const email = await getAtendimentoEmail();
      setEmail(email || '');

    })();
  }, []);

  async function getManualLink() {
    const data = await GET_SITE_CONFIG({ keyFilter: 'DRYLOCK_MANUAL_URL' });
    if (data.value != null && data.value.length > 0) {
      return data.value[0].value;
    }

    return '';
  }

  async function getAtendimentoHorario() {
    const data = await GET_SITE_CONFIG({ keyFilter: 'ATENDIMENTO_TEXTO_HORARIO' });
    if (data.value != null && data.value.length > 0) {
      return data.value[0].value;
    }

    return '';
  }

  async function getAtendimentoTelefone() {
    const data = await GET_SITE_CONFIG({ keyFilter: 'ATENDIMENTO_TELEFONE' });
    if (data.value != null && data.value.length > 0) {
      return data.value[0].value;
    }

    return '';
  }

  async function getAtendimentoEmail() {
    const data = await GET_SITE_CONFIG({ keyFilter: 'ATENDIMENTO_EMAIL' });
    if (data.value != null && data.value.length > 0) {
      return data.value[0].value;
    }

    return "";
  }

  return (
    <div className="wrapper-atendimento">
      <div className="title">Contato</div>
      <div className="atendimento">
        <form>
          <div className="row ">
            <label className="col-md-4 text-md-right">
              Horário de atendimento
            </label>
            <span className="col-md-8 ">
              {horario}
            </span>
          </div>
          <div className="row">
            <label className="col-md-4 text-md-right">
              Telefone para contato
            </label>
            <span className="col-md-8">{telefone}</span>
          </div>
          <div className="row">
            <label className="col-md-4  text-md-right">
              E-mail para contato
            </label>
            <span className="col-md-8 ">{email} </span>
          </div>
          <div className="row download">
            <div className="offset-md-4 col-md-8">
              <Button>
                <a
                  href={download}
                  target="_blank"
                  download="Manual.pdf"
                  className="col-md-6 col-sm-6 botao"
                  variant="primary"
                >
                  <FontAwesomeIcon icon={faDownload} />{' '}
                  <small>Baixar Manual do Sistema</small>
                </a>
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
