/* eslint-disable prefer-destructuring */
/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import { FaSave } from 'react-icons/fa';
import VMasker from 'vanilla-masker';

import api from 'api';
import {
  GET_LIST_FILIAL_REGIONAL,
  GET_LIST_REGIONAL_SUPERIOR,
  GET_SELECT_LIST_FILHAS,
} from 'services/Regional';
import {
  GET_USUARIO,
  GET_LIST_USUARIO_TIPO,
  GET_LIST_USUARIO_SUPERIOR,
  UPDATE_DADOS_CADASTRO_USUARIO,
} from 'services/Usuario';
import { Context } from 'context/AuthContext';

import TitlePages from 'components/_base/TitlePages';
import Select from 'react-select';

const ContentValidacaoDadosUsuario = () => {
  const { showLoader, userData, setUserData } = useContext(Context);
  const [formData, setFormData] = useState({
    userId: '',
    nome: '',
    telefone: '',
    email: '',
    senhaAtual: '',
    novaSenha: '',
    novaSenhaConfirmacao: '',
    erros: [],
  });

  useEffect(() => {
    (async () => {
      showLoader(true);

      const userAuth = JSON.parse(localStorage.getItem('encartes_drylock_site'));
      const { value: dadosUserOn } = await GET_USUARIO(userAuth.userId);
      setFormData({
        ...formData,
        userId: dadosUserOn.usuarioId,
        nome: dadosUserOn.nome,
        telefone: dadosUserOn.telefone,
        email: dadosUserOn.email,
      });
      showLoader(false);
    })();
  }, []);

  const telefoneValue = (numeroNormalizado) => {
    let input = numeroNormalizado || '';
    input = VMasker.toPattern(input, '99999999999999999999');
    if (input.length > 10) {
      input = VMasker.toPattern(input, '(99) 99999-9999');
    } else {
      input = VMasker.toPattern(input, '(99) 9999-9999');
    }
    return input;
  };

  const validacaoForm = () => {
    let erros = [];

    !formData.nome && erros.push('O campo Nome é obrigatório.');
    let validarEmail = /\S+@\S+\.\S+/;
    if (!formData.email || !validarEmail.test(formData.email)) {
      erros.push(`O campo Email é obrigatório.`);
    }
    if (!formData.telefone || formData.telefone.length < 10) {
      erros.push(`O campo Telefone é obrigatório.`);
    }
    !formData.senhaAtual && erros.push('O campo Senha atual é obrigatório.');
    !formData.novaSenha && erros.push('O campo Nova senha é obrigatório.');
    !formData.novaSenhaConfirmacao &&
      erros.push('O campo Confirme nova senha é obrigatório.');
    formData.novaSenhaConfirmacao !== formData.novaSenha &&
      erros.push(
        'O campo Nova senha e confirmar nova senha precisa ser igual.'
      );
    if (
      formData.novaSenhaConfirmacao === formData.novaSenha &&
      formData.novaSenhaConfirmacao &&
      formData.novaSenha
    ) {
      let regexValidarSenha = /^.{4,}$/;
      !regexValidarSenha.test(formData.novaSenha) &&
        erros.push(
          'A senha deve conter pelo menos 4 caracteres.'
        );
    }
    setFormData({
      ...formData,
      erros: erros,
    });

    if (erros.length !== 0) {
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    let validacao = validacaoForm();
    if (validacao) {
      showLoader(true);
      let requestBody = {
        usuario: {
          usuarioId: formData.userId,
          nome: formData.nome,
          email: formData.email,
          telefone: formData.telefone,
        },
        senhaAtual: formData.senhaAtual,
        novaSenha: formData.novaSenha,
        confirmacaoNovaSenha: formData.novaSenhaConfirmacao,
      };
      const atualizacaoEnvio = await UPDATE_DADOS_CADASTRO_USUARIO(requestBody);
      if (atualizacaoEnvio.value) {
        let userAuth = JSON.parse(localStorage.getItem('encartes_drylock_site'));
        userAuth = {
          ...userAuth,
          atualizarDados: false,
        };
        setUserData(userAuth);
        localStorage.setItem('encartes_drylock_site', JSON.stringify(userAuth));
      } else {
        setFormData({ ...formData, erros: atualizacaoEnvio.errors });
      }
      showLoader(false);
    }
  };

  return (
    <div className="container-fluid mb-5">
      <div className="row">
        <TitlePages title={`CONFIRME OS SEUS DADOS`} />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1 mb-2 mt-2">
            {formData.erros && formData.erros.length !== 0 && (
              <div className="alert alert-danger">
                {formData.erros.map((value, index) => {
                  return (
                    <p className="m-0" key={index}>
                      {value}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
          <div className="col-md-10 offset-md-1 mb-3">
            <label>Nome Completo</label>
            <input
              type="text"
              className="form-control"
              value={formData.nome}
              onChange={(e) =>
                setFormData({ ...formData, nome: e.target.value })
              }
            />
          </div>
          <div className="col-md-5 offset-md-1 mb-3">
            <label>Telefone de contato</label>
            <input
              type="text"
              className="form-control"
              value={telefoneValue(formData.telefone)}
              onChange={(e) => {
                let phoneNoMask = e.target.value.replace(/\D+/g, '');
                if (phoneNoMask.length <= 11) {
                  setFormData({
                    ...formData,
                    telefone: e.target.value.replace(/\D+/g, ''),
                  });
                }
              }}
            />
          </div>
          <div className="col-md-5 mb-3">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </div>
          <div className="col-10 offset-1 mb-3">
            <label>Senha atual</label>
            <input
              type="password"
              className="form-control"
              value={formData.novasenhaconfirmacao}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  senhaAtual: e.target.value,
                })
              }
            />
          </div>
          <div className="col-md-5 offset-md-1 mb-3">
            <label>Nova senha</label> <br />
            <input
              type="password"
              className="form-control"
              value={formData.novaSenha}
              onChange={(e) =>
                setFormData({ ...formData, novaSenha: e.target.value })
              }
            />
            <label className="text-secondary">
              Minimo 4 caracteres.
            </label>
          </div>
          <div className="col-md-5 mb-3">
            <label>Confirmar nova senha</label>
            <input
              type="password"
              className="form-control"
              value={formData.novaSenhaConfirmacao}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  novaSenhaConfirmacao: e.target.value,
                })
              }
            />
            <label className="text-secondary">
              Minimo 4 caracteres.
            </label>
          </div>
          <div className="col-10 offset-1 mt-2 col btn-avancar-voltar d-flex flex-row-reverse">
            <button
              className={`btn btn-primary btn-lg btn-navigation btn-navigation-right pl-5 pr-5 ${
                true ? '' : 'btn-disabled'
              }`}
              onClick={() => onSubmit()}
            >
              <span className="mr-2">
                <FaSave size={26} />
              </span>
              <span>SALVAR</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentValidacaoDadosUsuario;
