import React from 'react';
import './styles.scss';

const CardsGerencial = React.memo(
  ({ data }) => {
    if (!data) return null;
    return (
      <div>
        <div className="card-wrapper">
          <div className="card">
            <p className="card-title">Total de solicitações</p>
            <p className="card-data">
              {parseInt(data?.solicitacao, 10).toLocaleString('pt-BR')}
            </p>
          </div>
          <div className="card">
            <p className="card-title">Solicitações aprovadas</p>
            <p className="card-data">
              {parseInt(data?.solicitacaoAprovada, 10).toLocaleString('pt-BR')}
            </p>
          </div>
          <div className="card">
            <p className="card-title">Arte Finalizada</p>
            <p className="card-data">
              {parseInt(data?.totalArteAprovada, 10).toLocaleString('pt-BR')}
            </p>
          </div>
          <div className="card">
            <p className="card-title">Verba Utilizada</p>
            <p className="card-data">
              R${` `}
              {parseInt(data?.verbaUtilizada / 1000, 10).toLocaleString(
                'pt-BR'
              )}
              K
            </p>
          </div>
          {/* <div className="card">
            <p className="card-title">Arte de Encarte Finalizada</p>
            <p className="card-data">
              {parseInt(data?.totalEncarteAprovado, 10).toLocaleString('pt-BR')}
            </p>
          </div> */}
        </div>
        {/* <div className="card-wrapper">
          <div className="card">
            <p className="card-title">Encartes Impressos Aprovados</p>
            <p className="card-data">
              {parseInt(data?.totalEncarteImpresso, 10).toLocaleString('pt-BR')}
            </p>
          </div>
          <div className="card">
            <p className="card-title">Verba Disponibilizada</p>
            <p className="card-data">
              R${` `}
              {parseInt(data?.verbaDisponivel / 1000, 10).toLocaleString(
                'pt-BR'
              )}
              K
            </p>
          </div>
          <div className="card">
            <p className="card-title">Saldo Verba</p>
            <p className="card-data">
              R${` `}
              {parseInt(
                (data?.verbaDisponivel - data?.verbaUtilizada) / 1000,
                10
              ).toLocaleString('pt-BR')}
              K
            </p>
          </div>
        </div> */}
      </div>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data);
  }
);

export default CardsGerencial;
