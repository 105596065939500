/* eslint-disable */
import React from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import './styles.scss';
import TitlePages from 'components/_base/TitlePages';
import SubHeader from 'components/SubHeader';
import { tabs, passos } from '../../_constants';

import TipoMaterialLista from './TipoMaterialLista';

const Materiais = (props) => {
  let updateRascunho = props.updateRascunho;
  let model = props.model;
  let entities = props.entities;
  let setModel = props.setModel;
  let renderErrors = props.renderErrors;
  let setActiveTab = props.setActiveTab;

  let errors = (model.errors || []).filter(
    (error) => error.passo === passos.passoMateriais.nro
  );
  model.solicitacoes = model.solicitacoes || [];

  // const materiais = []
  //   .concat(
  //     ...(entities.tipoMateriais || []).map(
  //       (tipoMaterial) => tipoMaterial.materiaisComunicacao
  //     )
  //   )

  const tipoMateriais = (entities.tipoMateriais || []).filter(tipoMaterial =>
    (!model.materialTipoSigla?.length || model.materialTipoSigla.length === 0 || tipoMaterial?.sigla === model.materialTipoSigla)) || [];

  let materiais = [];
  tipoMateriais?.forEach(element => {
    materiais = materiais.concat(element.materiaisComunicacao || []);
  });

  return (
    <div className="wrapper-content-pedido-materiais">
      <SubHeader title={model.loja?.razaoSocial} />

      <TitlePages title="Selecione os Materiais" />

      <div className="content-pedido-materiais">
        {renderErrors(errors)}

        <TipoMaterialLista
          materiais={materiais}
          model={model}
          entities={entities}
        />

        <div className="row buttons">
          <div className="col">
            <button
              className="btn btn-primary btn-navigation"
              onClick={() => {
                updateRascunho();
                setActiveTab(tabs.tabCliente);
              }}
            >
              <span>
                <FaAngleLeft size={26} />
              </span>
              <span>VOLTAR</span>
            </button>
          </div>

          <div className="col flex-row-reverse">
            <button
              className="btn btn-primary btn-navigation btn-navigation-right"
              onClick={() => {
                updateRascunho();
                setActiveTab(tabs.tabEntrega);
              }}
            >
              <span>AVANÇAR</span>
              <span>
                <FaAngleRight size={26} />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Materiais;
