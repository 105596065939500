import React from 'react';

import {
  CategoriaEncarte,
  Histograma,
  // HistogramaNaoObrigatorio,
  // HistogramaObrigatorio,
  // ObrigatorioEncarte,
  PrecoMedioEncarte,
  PrecoMedioProduto,
  ProdutoEncarte,
  RedeArte,
  RegionalEncarte,
  UFEncarte,
} from '../Graficos/participacao';

const TabParticipacao = React.memo(
  (props) => {
    const { data } = props;
    return (
      <div className="container-tab">
        <div className="row-graficos">
          <UFEncarte data={data.participacaoUF} />
          <RegionalEncarte data={data.participacaoRegional} />
          <RedeArte data={data.participacaoRede} />
        </div>
        {/* <div className="row-graficos">
          <ObrigatorioEncarte data={data.encarteProdutoObrigatorio} />
        </div> */}
        <div className="row-graficos">
          <CategoriaEncarte data={data.encarteCategoria} />
          <ProdutoEncarte data={data.encarteProduto} />
        </div>
        <div className="row-graficos">
          <PrecoMedioEncarte data={data.mediaEncarte} />
        </div>
        <div className="row-graficos">
          {/* <HistogramaNaoObrigatorio data={data.histogramaProduto} />
          <HistogramaObrigatorio data={data.histogramaProduto} /> */}
          <Histograma data={data.histogramaProduto} />
        </div>
        <div className="row-graficos">
          <PrecoMedioProduto
            data={data.mediaProduto}
            dataLabels={data.labelsRegional}
          />
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data);
  }
);

export default TabParticipacao;
